/**
 * This file is used to map the template name with the json content file.
 * Add more mappings as needed
 */

export const TEMPLATE_DETAIL_MAPPING: Record<string, string> = {
    'Dynamics-365-Customer-Experience-and-Service-Demo': 'solutionplay-template-detail',
    'Low-Code': 'lowcode-template-detail',
    'Industry-Manufacturing': 'manufacturing-demo-template-detail',
    'CoE-Toolkit': 'coe-toolkit-template-detail',
    'Industry-FSI': 'financial-services-template-detail',
    'Finance-And-Operations': 'fno-template-detail',
    'Industry-Retail': 'retail-template-detail'
};