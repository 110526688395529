import { useState, useEffect } from 'react';
import {
    DefaultButton,
    Label,
    PrimaryButton
} from '@fluentui/react';
import { Button } from "@fluentui/react-components";
import { BaseProps } from 'Shared/Components';
import styles from '../../copilotassets-page.module.scss';
import { CommonUtils } from 'Shared/utils/common-utils';
import { DateUtils } from 'Shared/utils/date-utils';
import { VideoDialog } from '../video-dailog/VideoDialogComponent';
import { CopilotAsset } from 'Models';
import Highlighter from "react-highlight-words";
import { AuthProvider } from 'Providers/Authentication';
import { AssetHubSvc } from 'Services';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { logUnhandledExceptionAndSetMetrics } from 'Shared/telemetry/telemetryHelper';
import { operationName } from "Shared/constants";
import { ExtendedMap, IDimension } from "Telemetry";
import { Dimensions, ErrorCode, Metrics } from "../../telemetry";
import { normalizeTitle } from 'Shared/utils/normalize-title';

interface ICopilotAssetCardProps extends BaseProps {
    assetOwner:string;
    id: string;
    title: string;
    description?: string;
    isDescriptionRichtext?: boolean;
    imageUrl?: string;
    videoUrl?: string; // New prop for video URL
    primaryButtonId?: string;
    primaryButtonText?: string;
    onPrimaryButtonClick?: () => void;
    disablePrimaryButton?: boolean;
    secondaryButtonId?: string;
    secondaryButtonText?: string;
    onSecondaryButtonClick?: (asset: CopilotAsset) => void;
    disableSecondaryButton?: boolean;
    createdOn?: string;
    iconProps?: any;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    asset: CopilotAsset;
    productName?: string;
    modified?:string;
    titleSearchInput:string;
    ids?: number;
    upvote?: string;
}

export const CopilotAssetCard = (props: ICopilotAssetCardProps) => {
    const { iconProps } = props;
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close
    const [isTextCopied, setIsTextCopied] = useState(false); // State for each card to track if text is copied

    const requestType = "UserTriggered";
    const account = AuthProvider.getActiveAccount();
    const currentUserId = account?.idTokenClaims?.oid as string

    const [upvoteUserIds, setUpvoteUserIds] = useState<string[]>([]);
    const [upvoteCount, setUpvoteCount] = useState(0);
    const [error, setError] = useState<any>(null);

    let telemetryProps: TelemetryProperties = {
        operationName: operationName.CopilotAssetsFetchUpvotes,
        exceptionType: ExceptionType.CopilotAssetsFetchUpvotesException,
        defaultErrorCode: ErrorCode.FailedToFetchCopilotAssetsUpvote
    }

    let dimensions = new ExtendedMap<IDimension, string>([
        [Dimensions.AssetId, props.id],
    ]);

    useEffect(() => {
        if (props.upvote) {
            // Replace single quotes with double quotes and parse the string
            const cleanedUpvoteString = props.upvote.replace(/'/g, '"');
            try {
                const parsedUpvotes = JSON.parse(cleanedUpvoteString);
                setUpvoteUserIds(parsedUpvotes);
                setUpvoteCount(parsedUpvotes.length);
            } catch (error) {
                let dimensions = new ExtendedMap<IDimension, string>([
                    [Dimensions.RequestType, requestType],
                ]);
                setError(error)
                logUnhandledExceptionAndSetMetrics(
                    'Failed to Fetch Upvotes',
                    {
                        ...telemetryProps,
                        message: telemetryProps.exceptionType,
                        defaultErrorCode: ErrorCode.FailedToFetchCopilotAssetsUpvote
                    },
                    Metrics.CopilotAssetsFetchAPICounter,
                    dimensions
                );
            }
        }
    }, [props.upvote]);

    const handleUpvote = async () => {
        let updatedUserIds, updatedCount;
        if (upvoteUserIds.includes(currentUserId)) {
            // User has already upvoted, so remove their ID and decrement the count
            updatedUserIds = upvoteUserIds.filter(id => id !== currentUserId);
            updatedCount = upvoteCount - 1;
        } else {
            // User hasn't upvoted, so add their ID and increment the count
            updatedUserIds = [...upvoteUserIds, currentUserId];
            updatedCount = upvoteCount + 1;
        }

        setUpvoteUserIds(updatedUserIds);
        setUpvoteCount(updatedCount);

        try {
            const upvoteString = updatedUserIds.length > 0 ? `['${updatedUserIds.join("','")}']` : '[]';
            const response = await AssetHubSvc.updateUpvote(
                {
                    Upvote: upvoteString,
                    Id: props.ids,
                }
            );

            if (response.status !== 200) {
                // Revert the state if the request failed
                setUpvoteUserIds(upvoteUserIds);
                setUpvoteCount(upvoteCount);
            }
        } catch (error) {

            let telemetryProps: TelemetryProperties = {
                operationName: operationName.CopilotAssetsUpdateUpvotes,
                exceptionType: ExceptionType.CopilotAssetsUpdateUpvotesException,
                defaultErrorCode: ErrorCode.FailedToUpdateCopilotAssetsUpvote
            }
            
            setUpvoteUserIds(upvoteUserIds);
            setUpvoteCount(upvoteCount);
            setError(error)
            logUnhandledExceptionAndSetMetrics(
                'Failed to Update upvote',
                {
                    ...telemetryProps,
                    message: telemetryProps.exceptionType,
                    defaultErrorCode: ErrorCode.FailedToUpdateCopilotAssetsUpvote
                },
                Metrics.CopilotAssetsFetchAPICounter,
                dimensions
            );
        }
    };

    const hasUserUpvoted = upvoteUserIds.includes(currentUserId);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleVideoPlay = () => {
        // Open the VideoDialog when the user clicks on the video
        setIsModalOpen(true);
    };

    const highlightText = (text: string, search: string): JSX.Element => {
        return (
            <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={text}
            />
        );
    }

    //  converts the JSON string into a JavaScript object.
    const parseAssetOwners = (jsonString?: string): string[] => {
        try {
            if (jsonString) {
                const parsed = JSON.parse(jsonString);
                if (Array.isArray(parsed)) {
                    return parsed.map((item: { Title: string }) => item.Title);
                }
            }
        } catch (error) {
            console.error('Error parsing AssetOwner JSON:', error);
        }
        return [];
    };
    const assetOwners = parseAssetOwners(props.assetOwner);

    const onSecondaryButtonClick = (asset: CopilotAsset) => {
        const location = window.location;
        const normalizedTitle = normalizeTitle(asset.Title);
        const url = `${location.origin}${location.pathname}?name=${normalizedTitle}`;
       
        navigator.clipboard.writeText(url); // Copy URL to clipboard
        setIsTextCopied(true); // Indicate text is copied
       
        // Hide the message after 1 second
        setTimeout(() => {
          setIsTextCopied(false);
        }, 1000);
      };

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (isTextCopied) {
            timeoutId = setTimeout(() => {
                setIsTextCopied(false);
            }, 1000);
        }
        return () => clearTimeout(timeoutId);
    }, [isTextCopied]);

    return (
        <div
            className={styles.cardsContainer}
            role="figure"
            id={props.id}
            data-testid={props.id}>
            <div
                className={styles.card}>

                <VideoDialog isOpen={isModalOpen} onClose={handleCloseModal} videoUrl={props.videoUrl || ''} />
                <div className={styles.cardBody}>
                    {props.imageUrl &&
                        <div className={styles.cardImage}>
                            <img
                                src={props.imageUrl}
                                alt={props.title}>
                            </img>
                        </div>
                    }
                    {props.videoUrl && (
                        <iframe
                            width="560"
                            height="226"
                            src={props.videoUrl}
                            title="Video"
                            frameBorder="0"
                            scrolling="no"
                            allowFullScreen
                            onClick={handleVideoPlay}
                        ></iframe>
                    )}
                    <div className={styles.cardHeading}>
                        <Label
                            title={props.title}>
                            {highlightText(props.title, props.titleSearchInput)}
                        </Label>
                    </div>
                    {props.assetOwner && assetOwners.length > 0 && (
                        <p className={styles.ownersContainer}>
                            Asset Owner(s):
                            {assetOwners.slice(0, 2).map((owner: string, index: number) => (
                                <span key={index} className={styles.badge}>
                                    {owner}
                                </span>
                            ))}
                        </p>
                    )}
                    {!props.isDescriptionRichtext && <p
                        title={props.description}>
                        {highlightText(props.description || '', props.titleSearchInput)}
                    </p>
                    }
                    {props.isDescriptionRichtext && props.description &&
                        <p title={props.description}>
                            {CommonUtils.parseStringToHtml(props.description)}
                        </p>
                    }
                    {props.modified &&
                        <p className={styles.smallText}>
                            Updated:
                            <span title={props.modified}>
                                {DateUtils.formatDateToDDMonYYYY(props.modified)}
                            </span>
                        </p>
                    }


                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        className={`${styles.upvoteButton} ${hasUserUpvoted ? styles.upvoted : ''}`}
                        iconPosition="before"
                        icon={<img className={styles.upvoteButtonIcon}
                            src={hasUserUpvoted ? "/images/copilot-assets/check-icon.png" : "/images/copilot-assets/arrow-icon-up.png"}
                            alt={props.title}
                        />}
                        onClick={handleUpvote}
                    >
                        {hasUserUpvoted? 'Upvoted' : 'Upvote'}
                        <span className={styles.upvoteButtonSpan}> | {upvoteCount} votes</span>
                    </Button>
                    <div className={styles.copilotAssetActions}>
                    {props.secondaryButtonId &&
                        <div className={styles.actionButtons} style={{ position: 'relative' }}>
                            <DefaultButton
                                id={props.secondaryButtonId}
                                data-testid={props.secondaryButtonId}
                                text={props.secondaryButtonText}
                                ariaLabel={props.secondaryButtonText}
                                iconProps={iconProps}
                                onClick={() => onSecondaryButtonClick(props.asset)}
                                disabled={props.disableSecondaryButton}
                                {...props.dataAttributes} />
                            {isTextCopied && <p className={styles.textCopied}>Asset link copied</p>}
                        </div>
                    }
                    {props.primaryButtonId &&
                        <div className={styles.actionButtons}>
                            <PrimaryButton
                                id={props.primaryButtonId}
                                data-testid={props.primaryButtonId}
                                text={props.primaryButtonText}
                                ariaLabel={props.primaryButtonText}
                                onClick={props.onPrimaryButtonClick}
                                disabled={props.disablePrimaryButton}
                                {...props.dataAttributes} />
                        </div>
                    }         
                    </div>
                </div>
            </div>
        </div>
    )
};

