import { Dimensions } from "./Dimensions";

/*
Keep Metrics in Alphabetical order
*/
export const Metrics = {
    DemoRequestByIdAPIFailure: {
        metricName: 'DemoRequestByIdAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    DemoRequestByIdAPICounter: {
        metricName: 'DemoRequestByIdAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    GetTemplatesAPIFailure: {
        metricName: 'GetTemplatesAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    GetTemplatesAPICounter: {
        metricName: 'GetTemplatesAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    NewDemoRequestAPIFailure: {
        metricName: 'NewDemoRequestAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    NewDemoRequestAPICounter: {
        metricName: 'NewDemoRequestAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    ProvisionDemoAPIFailure: {
        metricName: 'ProvisionDemoAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    ProvisionDemoAPICounter: {
        metricName: 'ProvisionDemoAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    UpdateTemplateStatusFailure: {
        metricName: 'UpdateTemplateStatusFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },
};
