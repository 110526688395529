// Please add Api Names in alphabetical order.
export enum ApiName {
    AssetHub = 'copilotassets',
    Content = 'content',
    Copilot = 'copilot',
    Demos = 'demos',
    FeatureFlags = 'featureFlags',
    Nsat = 'nsat',
    Roles = 'roles',
    Templates = 'templates',
    Users = 'users',
    UserTemplates = 'usertemplates',
    Template = 'template',
    Nsats = 'nsats'
}