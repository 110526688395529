import {
    ApiConfig,
    ApiConfigs
} from 'Services/base/models';
import { NsatAccessTokenRequest } from 'Providers/Authentication/AuthConfig';
import { ApiName } from './enums';
import { areMocksEnabled } from './enableMocks';

const baseUri = '/api/v1/';
const nsatBaseUri = process.env.REACT_APP_NSAT_BASE_URL || 'https://world.tip1.ces.microsoftcloud.com';

export const USE_MOCKS = 'bapdemos/usemocks';
// Please add API config in alphabetical order.
const API_CONFIG = {
    copilotassets :{ BaseUri: `${baseUri}content/` },
    content: { BaseUri: `${baseUri}content/` },
    demos: { BaseUri: baseUri },
    featureFlags: { BaseUri: baseUri },
    nsat: { BaseUri: `${nsatBaseUri}/api/v1/`, Scopes: NsatAccessTokenRequest.scopes },
    roles: { BaseUri: baseUri },
    templates: { BaseUri: baseUri },
    users: { BaseUri: baseUri },
    usertemplates: { BaseUri: baseUri },
    nsats: { BaseUri: baseUri },
    copilot: { BaseUri: `${baseUri}copilot/` }
}; // TODO get from config

export const getApiConfig = (apiName: string): ApiConfig => {
    const isTest: boolean = false; // TODO: revisit this
    const TEST_API_CONFIG = { Test: { BaseUri: '{someSpecialTestToken}/some/end/point/here' } };
    const configs: ApiConfigs = isTest ? TEST_API_CONFIG : API_CONFIG;
    return configs[isTest ? 'Test' : apiName];
};

export const enableMocksFromUrl = (apiName: ApiName) => {
    const urlParams = new URLSearchParams(window.location.search.toLocaleLowerCase());
    const mockNames = urlParams.get(USE_MOCKS);
    const mockNameArray = mockNames?.split(',') || [];
    mockNameArray.forEach((mockName) => {
        if (mockName === apiName.toLocaleLowerCase()) {
            areMocksEnabled[apiName] = true;
        }
    });
};