import styles from '../../copilotassets-page.module.scss';

interface Option {
    key: string;
    text: string;
}

interface CheckboxListComponentProps {
    options: Option[];
    selectedValues: string[];
    onChange: (event: any) => void;
    isScrollable: boolean;
    optionLength: number;
    disabled?: boolean;
}

export const CheckboxListComponent = (props: CheckboxListComponentProps) => {
    const { isScrollable, optionLength =5 } = props;

    const dropdownClass = isScrollable && props.options.length > optionLength
        ? `${styles.customDropdown} ${styles.scrollableDropdown}`
        : styles.customDropdown;

    return (
        <div className={dropdownClass}>
            {props.options.map(option => (
                <div key={option.key} className={styles.checkboxItem}>
                    <input
                        type="checkbox"
                        id={option.key}
                        value={option.key}
                        checked={props.selectedValues.includes(option.key)}
                        onChange={props.onChange}
                        disabled={props.disabled}
                    />
                    <label htmlFor={option.key}>{option.text}</label>
                </div>
            ))}
        </div>
    );
};