import {
    MessageBar,
    MessageBarType,
    DefaultButton
} from '@fluentui/react';
import { CommonUtils } from 'Shared/Utils';

import styles from './message-box.module.scss';

interface MessageBoxProps {
    message: string;
    isMessageRichtext?: boolean;
    type?: 'info' | 'error' | 'success';
    actionButtonText?: string;
    onActionClick?: () => void;
    actionButtonId?: string;
    className?: string;
}

export const MessageBox = (props: MessageBoxProps) => {
    let messageBarType: MessageBarType = MessageBarType[props?.type || 'error'];
    return (
        <MessageBar
            messageBarType={messageBarType}
            isMultiline={true}
            className={`${styles.messageBoxContainer} messagebox-type-${props.type} ${props.className}`}
        >
            {props.isMessageRichtext ? CommonUtils.parseStringToHtml(props.message) : props.message}
            {
                props.actionButtonText &&
                <div className={styles.actionButtonContainer}>
                    <DefaultButton
                        id={props.actionButtonId}
                        text={props.actionButtonText}
                        onClick={props.onActionClick} />
                </div>
            }
        </MessageBar>
    )
}