/*
Keep Error Codes in Alphabetical order
*/
export const ErrorCode = {
    FailedToCreateNewDemoRequest: 'FailedToCreateNewDemoRequest',
    FailedToGetDemoRequestById: 'FailedToGetDemoRequestById',
    FailedToGetEnvironmentTemplates: 'FailedToGetEnvironmentTemplates',
    FailedToUpdateTemplateStatus: 'FailedToUpdateTemplateStatus',
    FailedToProvisionDemoEnvironment: 'FailedToProvisionDemoEnvironment',
    GetAllTemplatesUnhandledError: 'GetAllTemplatesUnhandledError',
    ProvisionDemoAdminAccountMismatch: 'CreateNewDemoAdminAccountMismatch',
    ProvisionDemoBapApiValidationFailed: 'CreateNewDemoBapApiValidationFailed',
    ProvisionDemoTenantUsersValidationFailed: 'CreateNewDemoTenantUsersValidationFailed',
    ProvisionDemoUnhandledError: 'CreateNewDemoUnhandledError',
    UnauthorizedToCreateNewDemoRequest: 'UnauthorizedToCreateNewDemoRequest',
    UpdateTemplateStatusUnhandledError:'UpdateTemplateStatusUnhandledError'
}