import { DefaultButton, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, SpinnerSize } from '@fluentui/react';
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchText } from 'Providers/Localization';
import { Loader, MessageBox } from 'Shared/Controls';
import { CommonUtils } from 'Shared/Utils';
import { links } from 'Shared/links';
import { useTemplateDetail } from './hooks/useTemplateDetail';
import styles from './templatedetails-page.module.scss';
import { TemplateDetail } from 'Models';
import { pageRoutes } from 'Pages';
import { FieldsListComponent } from './components/field-section/FieldsListComponent';
import { AssetsAndResources } from './components/assets-and-resources/AssetsResources';
import { MessageBanner } from './components/banner/MessageBanner';

export const TemplateDetailPage = () => {
    const {
        error,
        templateDetailPageContent,
        selectedTemplateName,
        isTemplateActiveFlag,
        isChooseTemplateButtonDisabled,
        isLoading,
        isTemplateInactiveCheck
    } = useTemplateDetail();

    const navigate = useNavigate();

    const onChooseTemplateButtonClick = (selectedTemplateName: string | null) => {
        if (selectedTemplateName) {
            navigate(`${pageRoutes.newDemo}?templateName=${encodeURIComponent(selectedTemplateName)}`);
        }
    };

    const onCancelClick = () => {
        navigate({
            pathname: pageRoutes.newDemo,
            hash: ''
        });
    };

    const [selectedTab, setSelectedTab] = useState<TemplateDetail[] | null>([]);

    useEffect(() => {
        // templateDetailPageContent is an object with properties and templateDetails is an array
        if (templateDetailPageContent && templateDetailPageContent.templateDetails) {
            setSelectedTab(templateDetailPageContent.templateDetails);
        }
    }, [templateDetailPageContent]);

    if (isLoading) {
        //Handle the case where isLoading is true (data is loading)
        return <Loader
            Message="Please wait.."
            Size={SpinnerSize.large} />
    }

    const TemplateDetails = () => {
        const params = new URLSearchParams(window.location.search);
        const category = params.get(
          "category"
        ) as keyof typeof tabMappingConfig;

        const tabMappingConfig = {
          "accelerate revenue generation": 0,
          "modernize the service experience": 1,
          "modernize erp": 0,
          "low code": 0,
          "shared demos": 0,
          "industry solutions": 0,
        };

        const defaultTabIndex = 0;

        const tabIndex = params.get("tab")
          ? parseInt(params.get("tab") || "0", 10) // Use the tab parameter if it exists
          : category && tabMappingConfig[category] !== undefined
          ? tabMappingConfig[category] // Use the category mapping if it exists
          : defaultTabIndex; // Default to the first tab if no tab or category is specified

        const activeTab = selectedTab && selectedTab[tabIndex];
        const [activeKey, setActiveKey] = useState<string | undefined>(
          activeTab?.DemoName
        );

        const handleLinkClick = (item?: PivotItem) => {
            if(selectedTab) {
                // Find the index of the clicked item in the items array
                const index = selectedTab.findIndex((value) => value.DemoName.toLowerCase() === item?.props.headerText?.toLowerCase());
                if(index >=0) {
                    setActiveKey(item?.props.headerText);
                    // Get the current URL
                    const currentUrl = window.location.href;
    
                    // Parse the URL
                    const url = new URL(currentUrl);
    
                    // Get existing query parameters
                    const params = new URLSearchParams(url.search);
    
                    // Add or update the 'tab' parameter
                    params.set('tab', index.toString());
                    
                    // Construct the new URL with updated query parameters
                    url.search = params.toString();
    
                    // Update the URL in the history without refreshing
                    window.history.pushState({}, '', url.toString()); 
                }
            }
          };

        
        if (!templateDetailPageContent || isLoading) {
            return <Loader
                Message="Please wait.."
                Size={SpinnerSize.large} />
        }
        if (!templateDetailPageContent?.templateDetails || templateDetailPageContent?.templateDetails?.length === 0) {
            return (
                <div className="container">
                    <h1>{templateDetailPageContent.heading}</h1>
                    <div className={styles.buttonContainer}>
                        <DefaultButton
                            text="Back"
                            onClick={onCancelClick}
                        />
                    </div>
                    <div className={styles.messageBarContainer} >
                        <MessageBanner
                            message={CommonUtils.parseStringToHtml(fetchText('NotFoundMessages.NoTemplateDetailsFoundMsg')?.replace('###', links.announcements))}
                            messageBarType={MessageBarType.info}
                        />
                    </div>
                </div>
            );
        }

        if (CommonUtils.redirectToErrorPage(error)) {
            return <Navigate to="/error" state={error} />
        }

        if (selectedTab === null || isLoading) {
            //Handle the case where selectedTab is null (data is loading)
            return <Loader
                Message="Please wait.."
                Size={SpinnerSize.large} />
        }

        return (
            <div className="container">
                <div className={styles.header}>
                    <h1>{templateDetailPageContent.heading}</h1>
                    <div className={styles.buttonContainer}>
                        <PrimaryButton
                            id={`btnCT-top-${selectedTemplateName}`}
                            text={templateDetailPageContent.primaryButtonText}
                            onClick={() => onChooseTemplateButtonClick(selectedTemplateName)}
                            disabled={isChooseTemplateButtonDisabled(isTemplateActiveFlag)}
                        />
                        <DefaultButton
                            id={`btnBack-top-${selectedTemplateName}`}
                            text="Back"
                            onClick={onCancelClick}
                        />
                    </div>
                </div>
                {isTemplateInactiveCheck(isTemplateActiveFlag) && (
                    <MessageBanner
                        message={CommonUtils.parseStringToHtml(
                            fetchText('NotFoundMessages.TemplateInActiveMsg')?.replace('###', links.announcements)
                        )}
                        messageBarType={MessageBarType.warning}
                    />
                )}
                <Pivot aria-label="templateDetailPageContent.heading" defaultSelectedKey={activeKey} onLinkClick={handleLinkClick}>
                    {selectedTab?.map((tab, index) => (
                        <PivotItem headerText={tab.DemoName} itemKey={tab.DemoName} key={index.toString()}>
                            <p><h2>{tab.DemoName}</h2></p>
                            {tab.DemoDescription && (
                                <p>{CommonUtils.parseStringToHtml(tab.DemoDescription)}</p>
                            )}
                            {tab.ImportantNote && (
                                <MessageBox
                                    message={(tab.ImportantNote)}
                                    type="info"
                                    isMessageRichtext={true}
                                />
                            )}
                            
                            <h3>{tab.SubHeadingTop}</h3>
                            {tab.SubHeadingTopDescription &&
                                <p>{CommonUtils.parseStringToHtml(tab.SubHeadingTopDescription)}</p>
                            }
                            <div className={styles.fieldList}>
                                {tab.TopFields && (
                                    <FieldsListComponent fields={tab.TopFields as { [key: string]: { Value: string; IconName: string } }} />
                                )}
                            </div>
                            <div className={styles.assetsAndfeedback}>
                                {tab.TopAssets && <AssetsAndResources assets={tab.TopAssets} sectionKey={`TopAssets-${selectedTemplateName}`} />}
                            </div>
                            {tab.ImageNotesTopHeading && (
                                <p><h3>{tab.ImageNotesTopHeading}</h3></p>
                            )}
                            {tab.ImageNotesTopDescription && (
                                <p>{CommonUtils.parseStringToHtml(tab.ImageNotesTopDescription)}</p>
                            )}
                            {tab.ImageUrlTop &&
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlTop}
                                        alt={tab.DemoName}>
                                    </img>
                                </div>
                            }
                            {tab.ImageNotesTop2Heading && (
                                <p><h3>{tab.ImageNotesTop2Heading}</h3></p>
                            )}
                            {tab.ImageNotesTop2Description && (
                                <p>{CommonUtils.parseStringToHtml(tab.ImageNotesTop2Description)}</p>
                            )}
                            {tab.ImageUrlTop2 &&
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlTop2}
                                        alt={tab.DemoName}>
                                    </img>
                                </div>
                            }
                            {tab.ImageNotesTop3Heading && (
                                <p><h3>{tab.ImageNotesTop3Heading}</h3></p>
                            )}
                            {tab.ImageNotesTop3Description && (
                                <p>{CommonUtils.parseStringToHtml(tab.ImageNotesTop3Description)}</p>
                            )}
                            {tab.ImageUrlTop3 &&
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlTop3}
                                        alt={tab.DemoName}>
                                    </img>
                                </div>
                            }
                            {tab.ImageNotesTop4Heading && (
                                <p><h3>{tab.ImageNotesTop4Heading}</h3></p>
                            )}
                            {tab.ImageNotesTop4Description && (
                                <p>{CommonUtils.parseStringToHtml(tab.ImageNotesTop4Description)}</p>
                            )}
                            {tab.ImageUrlTop4 &&
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlTop4}
                                        alt={tab.DemoName}>
                                    </img>
                                </div>
                            }
                            {/*{tab.ImageUrlTop && (
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlTop}
                                        alt={tab.DemoName}
                                        onClick={toggleImageSize} 
                                        className={isImageClicked ? styles.enlargedImage : ''}
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) { // Check for Enter key (key code 13)
                                                toggleImageSize(); // Call the function to enlarge the image
                                            }
                                        }}
                                    />
                                </div>
                            )}*/}
                            <div>
                                {tab.VideoUrlTop && (
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={tab.VideoUrlTop}
                                        title={tab.VideoUrlTopTitle || tab.DemoName}
                                        frameBorder="0"
                                        scrolling="no"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </div>
                            
                            {tab.SubNotesTopHeading && (
                                <p><h3>{tab.SubNotesTopHeading}</h3></p>
                            )}
                            {tab.SubNotesTopDescription && (
                                <p>{CommonUtils.parseStringToHtml(tab.SubNotesTopDescription)}</p>
                            )}
                            {tab.SubHeadingMiddle && (
                                <div className={styles.separator} />
                            )}
                            {tab.SubHeadingMiddle && (
                                <h3>
                                    {tab.SubHeadingMiddle}
                                </h3>
                            )}
                            {tab.SubHeadingMiddleDescription &&
                                <p>{CommonUtils.parseStringToHtml(tab.SubHeadingMiddleDescription)}</p>
                            }
                            <div className={styles.assetsAndfeedback}>
                                {tab.MiddleAssets && <AssetsAndResources assets={tab.MiddleAssets} sectionKey={`MiddleAssets-${selectedTemplateName}`} />}
                            </div>
                            <div className={styles.separator} />
                            <h3>{tab.SubHeadingBottom}</h3>
                            {tab.SubHeadingBottomDescription &&
                                <p>{CommonUtils.parseStringToHtml(tab.SubHeadingBottomDescription)}</p>
                            }
                            <div className={styles.fieldList}>
                                {tab.BottomFields && (
                                    <FieldsListComponent fields={tab.BottomFields as { [key: string]: { Value: string; IconName: string } }} />
                                )}
                            </div>
                            <div className={styles.assetsAndfeedback}>
                                {tab.BottomAssets && <AssetsAndResources assets={tab.BottomAssets} sectionKey={`BottomAssets-${selectedTemplateName}`} />}
                            </div>
                            {tab.ImageUrlBottom &&
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlBottom}
                                        alt={tab.DemoName}>
                                    </img>
                                </div>
                            }
                            {/*{tab.ImageUrlBottom && (
                                <div className={styles.cardImage}>
                                    <img
                                        src={tab.ImageUrlBottom}
                                        alt={tab.DemoName}
                                        onClick={toggleImageSize} // Add the click event handler
                                        className={isImageClicked ? styles.enlargedImage : ''}
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) { // Check for Enter key (key code 13)
                                                toggleImageSize(); // Call the function to enlarge the image
                                            }
                                        }}
                                    />
                                </div>
                            )}*/}
                            <div>
                                {tab.VideoUrlBottom && (
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={tab.VideoUrlBottom}
                                        title={tab.DemoName}
                                        frameBorder="0"
                                        scrolling="no"
                                        allowFullScreen
                                    />
                                )}
                            </div>
                            {tab.SubNotesBottomHeading && (
                                <h3>{tab.SubNotesBottomHeading}</h3>
                            )}
                            {tab.SubNotesBottomDescription && (
                                <p>{CommonUtils.parseStringToHtml(tab.SubNotesBottomDescription)}</p>
                            )}
                            {tab.SubHeadingBottom && (
                                <div className={styles.separator} />
                            )}
                            {tab.SubHeading3 && (
                                <h3>
                                    {tab.SubHeading3}
                                </h3>
                            )}                            
                            {tab.SubHeading3Description &&
                                <p>{CommonUtils.parseStringToHtml(tab.SubHeading3Description)}</p>
                            }
                            <div className={styles.assetsAndfeedback}>
                                {tab.Assets3 && <AssetsAndResources assets={tab.Assets3} sectionKey={`Assets3-${selectedTemplateName}`} />}
                            </div>
                            <div>
                                {tab.VideoUrlTop2 && (
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={tab.VideoUrlTop2}
                                        title={tab.VideoUrlTop2Title || tab.DemoName}
                                        frameBorder="0"
                                        scrolling="no"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </div>                        
                            {tab.SubHeading3 && (
                                <div className={styles.separator} />
                            )}
                            <h3>
                                {tab.SubHeading4}
                            </h3>
                            {tab.SubHeading4Description &&
                                <p>{CommonUtils.parseStringToHtml(tab.SubHeading4Description)}</p>
                            }
                            <div className={styles.assetsAndfeedback}>
                                {tab.Assets4 && <AssetsAndResources assets={tab.Assets4} sectionKey={`Assets4-${selectedTemplateName}`} />}
                            </div>
                            {tab.SubHeading4 && (
                                <div className={styles.separator} />
                            )}
                            <h3>
                                {tab.SubHeading5}
                            </h3>
                            {tab.SubHeading5Description &&
                                <p>{CommonUtils.parseStringToHtml(tab.SubHeading5Description)}</p>
                            }
                            <div className={styles.assetsAndfeedback}>
                                {tab.Assets5 && <AssetsAndResources assets={tab.Assets5} sectionKey={`Assets5-${selectedTemplateName}`} />}
                            </div>
                            {tab.SubHeading5 && (
                                <div className={styles.separator} />
                            )}
                            {document.body.scrollHeight > 500 && (
                                <div className={styles.buttonContainerBottom}>
                                    <PrimaryButton
                                        id={`btnCT-bottom-${selectedTemplateName}`}
                                        text={templateDetailPageContent.primaryButtonText}
                                        onClick={() => onChooseTemplateButtonClick(selectedTemplateName)}
                                        disabled={isChooseTemplateButtonDisabled(isTemplateActiveFlag)}
                                    />
                                    <DefaultButton
                                        id={`btnBack-bottom-${selectedTemplateName}`}
                                        text="Back"
                                        onClick={onCancelClick}
                                    />
                                </div>
                            )}
                        </PivotItem>
                    ))}
                </Pivot>
            </div>
        );
    };

    return (
        <div className={`sp-content ${styles.templateDetailPage}`}>
            <div className={styles.templateDetailContainer}>
                <TemplateDetails />
            </div>
        </div>
    )
}