import { IColumn } from '@fluentui/react/lib/DetailsList';
import { RequestFilter } from 'Services';
import { DemoType } from 'Services/Enums';

export const requestFilters: RequestFilter = {
    DemoType: DemoType.mydemos
}

export const pageSize = 3;

export const columns: IColumn[] = [
    {
        key: 'environmentUrl',
        name: 'Environment URL',
        ariaLabel: 'Environment Url',
        fieldName: 'EnvironmentUrl',
        minWidth: 300,
        maxWidth: 350
    },
    {
        key: 'userName',
        name: 'Username',
        fieldName: 'UserName',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
    },
    {
        key: 'status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
    },
    {
        key: 'createdOn',
        name: 'Created on',
        fieldName: 'CreatedOn',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
    },
];

export const getKey = (item: any, index?: number): string => {
    return item.key;
}

export const messages = {
    noRecordsFound: "It looks like you haven't created any demo environment yet. No Problem, it's easy to get started! Simply click on Request demo environment button to get started.",
    noClickthroughRecordsFound: "It looks like you haven't pinned any Clickthroughs yet. No Problem, it's easy to get started! Simply click on Request demo environment and Pin any Clickthrough.",
    loadingDemos: 'Please wait while we fetch your demos...',
    loaderUnPinClickthroughRequestSubmission: 'Submitting request to UnPin clickthrough. Please wait...',
    loadingClickthroughs: 'Please wait while we fetch your clickthroughs...',
    pageHeading: 'My Demo Environments',
    ariaLabelEnvLinkIcon: 'Environment url icon',
    ariaLabelBtnDemoAssets: 'Click to get demo assets',
    ariaLabelBtnDemoScript: 'Click to get demo script',
    ariaLabelBtnConfigSteps: 'Click to get demo configuration steps',
    ariaLabelDemoUserIcon: 'Demo tenant user',
    ariaLabelDemoRequestDateIcon: 'Demo request date',
    ariaLabelDemoRequestRemoveButton: 'Click to remove demo',
    placeholderEnvironmentLink: 'Environment Url is not available yet.',
    environmentUrl: 'Environment Url',
    btnDemoAssetsText: 'Demo Assets',
    btnDemoScriptsText: 'Demo Script',
    btnConfigStepsText: 'Configuration Steps',
    clickthroughOpenBtnText: 'Open',
    clickthroughOpenBtnAriaLabel: 'Click to open Clickthrough request',
    clickthroughUnPinBtnText: 'UnPin',
    clickthroughUnPinBtnAriaLabel: 'UnPin Clickthrough request',
}