import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiResult } from '../base/models';
import { AssetHubContract } from './assetHubSvcContract';
import { Upvote,UpvoteResponse } from './models/Upvote';
import { mapApiResponse, } from './assetHub.Svc.transform';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';

const client = create(
    ApiName.AssetHub,
    createEnableMocksFn(async () => import('./asseHubSvc.mock'))
);

export class AssetHubService implements AssetHubContract {

    public getUpvote = async (): Promise<ApiResult<UpvoteResponse>> => {
        try {
            var endpoint = `copilotassets/upvote`; 
            let response = await client.get(endpoint);
            return apiSuccessResponse(response, mapApiResponse);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }

    public updateUpvote = async (payload: Upvote): Promise<ApiResult<Upvote>> => {
        try {
            const endpoint = `copilotassets/updateupvote`;
            const response = await client.post(endpoint, payload);
            return apiSuccessResponse(response, mapApiResponse);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
}
