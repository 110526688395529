/**
 * Keep Telemetry Messages in Alphabetical order
 */
export const telemetryMessages = {
    addUserTemplateMappingError: 'An error occurred while adding the user template mapping',
    addUserTemplateMappingSuccess: 'Successfully added the user template mapping',
    addUserTemplateMappingUnhandledException: 'An unhandled exception occurred while adding the user template mapping',
    cesRenderSurveySuccess: 'CES render survey completed',
    cesRenderSurveyError: 'CES render survey error',
    contentLoadingError: 'An error occurred while fetching portal content',
    createNewDemoError: 'An error occurred while creating the new demo request',
    createNewDemoSuccess: 'Successfully created the new demo request',
    createNewDemoUnauthorized: 'Unauthorized to create the new demo request',
    demoProvisioningError: 'An error occurred while provisioning the demo',
    demoProvisioningSuccess: 'Successfully provisioned the demo request',
    fetchMyDemosError: 'An error occurred while fetching the my demos',
    fetchMyDemosSuccess: 'Successfully fetched my demos',
    fetchMyDemosUnhandledException: 'An unhandled exception occurred while fetching my demos',
    fetchPortalContentError: 'An error occurred while fetching the portal content',
    fetchPortalContentSuccess: 'Successfully fetched portal content',
    fetchPortalContentUnhandledException: 'An unhandled exception occurred while fetching portal content',
    getDemoRequestbyIdError: 'An error occurred while fetching the demo request by id',
    getDemoRequestbyIdSuccess: 'Successfully fetched the demo request by id',
    getFeatureFlagsError: 'An error occurred while fetching feature flags',
    getFeatureFlagsSuccess: 'Successfully fetched feature flags',
    getFeatureFlagsUnhandledException: 'An unhandled exception occurred while fetching feature flags',
    getTemplatesError: 'An error occurred while fetching the templates',
    getTemplatesSuccess: 'Successfully fetched the demo templates',
    getTemplatesUnhandledException: 'An unhandled exception occurred while fetching the templates',
    getUserTemplatesByDemoTypeError: 'An error occurred while fetching the user templates by demo type',
    getUserTemplatesByDemoTypeSuccess: 'Successfully fetched the user templates by demo type',
    getUserTemplatesByDemoTypeUnhandledException: 'An unhandled exception occurred while fetching the user templates by demo type',
    getTemplatesByNameError: 'An error occurred while fetching the template details by template name',
    getTemplatesByNameSuccess: 'Successfully fetched the template details by template name',
    getTemplatesByNameUnhandledException: 'An unhandled exception occurred while fetching the template details by template name',
    getRolesError: 'An error occurred while fetching the user roles',
    getRolesSuccess: 'Successfully fetched the user roles',
    globalContextRegistrationUnhandledError: 'Exception occurred while registering global context',
    nsatCheckEligibilityError: 'Error checking survey eligibility',
    nsatCheckEligibilitySuccess: 'Successfully checked Nsat survey eligibility',
    nsatCheckEligibilityUnhandledException: 'An unhandled exception occurred while checking survey eligibility',
    nsatRenderSurveyAccessTokenEmpty: 'NsatService.renderSurvey returning without rendering survey due to null accessToken',
    nsatRenderSurveyError: 'Error rendering Nsat survey',
    nsatRenderSurveyInitiated:'NsatRenderSurveyInitiated',
    nsatRenderSurveySuccess: 'NsatService.renderSurvey completed',
    nsatRenderSurveyNullArguments: 'NsatService.renderSurvey returning without rendering survey due to null argument',
    nsatRenderSurveyUnhandledException: 'CES API Failure - renderSurvey',
    nsatSendNsatEmailNullArguments: 'NsatService.sendNsatEmail returning without sending survey email due to null argument',
    nsatSendNsatEmailUnhandledException: 'Send Nsat Survey Email API Failure - sendNsatEmail',
    openClickthrough: 'Opening clickthrough url from my demos page',
    refreshDemoOrgsError: 'An error occurred while refreshing demo orgs from PPAC',
    registerSellerError: 'An error occurred while registering the user as seller',
    registerSellerSuccess: 'Successfully registered the user as seller',
    removeUserTemplateMappingError: 'An error occurred while removing the user template mapping',
    removeUserTemplateMappingSuccess: 'Successfully removed the user template mapping',
    removeUserTemplateMappingUnhandledException: 'An unhandled exception occurred while removing the user template mapping',
    roleProviderUserRegistrationUnhandledError: 'Exception occurred while validating user access and registering user details',
    updateTemplateStatusUnhandledException: 'updateTemplateStatusUnhandledException',
    ToggleAdminSecurityGroupException: 'An unhandled exception occured while checking the Template Toggle Admin Status'
}