import { Link } from '@fluentui/react';
import { BaseProps, Card } from 'Shared/Components';
import styles from '../../home.module.scss';
import { SolutionPlayDemo } from 'Models/solutionplaydemo';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IconLoader } from 'Shared/Controls';
import { fetchText } from 'Providers/Localization';
import { pageRoutes } from 'Pages/Layout/Routes/RoutesConfig';

interface SolutionPlayDemoProps extends BaseProps {
    heading: string;
    exploreAllDemos: string;
    solutionPlayDemos: SolutionPlayDemo[] | null;
}

export const SolutionPlayDemos = (props: SolutionPlayDemoProps) => {
    const { className, heading, exploreAllDemos, solutionPlayDemos } = props;
    const allCategory = fetchText('TemplatePage.DefaultCategory');
    const navigate = useNavigate();
    const onPrimaryButtonClick = (templateCategory: string) => {
       navigate({
        pathname: pageRoutes.newDemo,
        search: createSearchParams({
            category: templateCategory.toLowerCase()
        }).toString()
    });
    }

    const SolutionPlayDemoList = () => {
        return (
            <>
                {solutionPlayDemos?.map((solutionPlayDemo: SolutionPlayDemo, index: number) => {
                    return (
                        <Card
                            key={`solution-play-demo-${index}`}
                            id={`solution-play-demo-${index}`}
                            title={solutionPlayDemo.Heading}
                            showColorBand={false}
                            showToggleButton={false}
                            showStatusIcon={false}
                            description={solutionPlayDemo.Description}
                            isDescriptionRichtext={true}
                            imageUrl={solutionPlayDemo.ImageUrl}
                            primaryButtonId={`btn-explore-demos-${index}`}
                            primaryButtonText={solutionPlayDemo.ButtonText}
                            onPrimaryButtonClick={() => onPrimaryButtonClick(solutionPlayDemo.ButtonLinkedTemplate)}
                        />
                    )
                })}
            </>
        )
    }

    return (
        <div className={className}>
            <div className={styles.header}>
                <h2 className={styles.heading}>
                    {heading}
                </h2>
                <Link onClick={() => onPrimaryButtonClick(allCategory)} className={styles.link}>
                    <span className={styles.exploreAll}>{exploreAllDemos}</span>
                    <IconLoader iconName="OpenInNewWindow" className={styles.icon} ariaLabel={exploreAllDemos} />
                </Link>                
            </div>
            <div className={styles.solutionPlayCardsContainer}>
                <div className={styles.solutionPlayCards}>
                    <SolutionPlayDemoList />
                </div>
            </div>
        </div>
    )
}