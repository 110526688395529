import React, { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { telemetryLogger } from 'Providers/Telemetry';
import { AuthProvider } from 'Providers/Authentication';
import { useForm } from 'Shared/hooks/useForm';
import {
    DemoProvisioning,
    DemoRequestSvc,
    NewDemo,
    UserTemplateSvc
} from 'Services';
import { DemoType } from 'Services/Enums';
import { SharedDemosCategory, UrlQueryParams, operationName } from 'Shared/constants';
import {
    NewDemoFormState,
    FormSubmissionState,
    formModel,
    getDemoRequestId,
    getDemoRequest,
    getDemoProvisioningError,
    successCodes,
    errorCodes,
    errorTypes,
    messages,
    FormValidationState,
    validateNewRequestForm,
    dependencyErrCodeMapping
} from '../newdemo.settings';
import { Template } from 'Models';
import { pageRoutes } from 'Pages';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ExtendedMap, IDimension } from 'Telemetry';
import { getErrorCode, logAndSetApiSuccessMetrics, logErrorAndSetApiFailureMetrics, logUnhandledExceptionAndSetMetrics } from 'Shared/telemetry/telemetryHelper';
import { Dimensions, ErrorCode, Metrics } from '../telemetry';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { CommonUtils } from 'Shared/Utils';
import { UserTemplateMgr } from 'Managers';
import { BaseApiResponse, DependencyError } from 'Services/base/models/ApiResult';

const defaultFormState: FormSubmissionState = {
    demoProvisioningError: { errorMessage: '' },
    isValidFormInput: false,
    serverError: '',
    validatingDemoRequest: false
}

const defaultFormReadinessState: NewDemoFormState = {
    formReady: false,
    selectedTemplate: undefined,
}

const defaultFormValidationState: FormValidationState = {
    allowedTenantsForTemplate: [] || undefined,
    tenantsList: [] || undefined
}

// for shared demos Environment name is fixed and one per user, mapping is as per template name
const sharedDemosEnvironmentNameMapping: { [key: string]: string | undefined } = {
    "CoE Toolkit": "CoE Toolkit Demo"
};

export const useNewDemoForm = (templateId?: string | null) => {
    const navigate = useNavigate();
    const [formState, setFormState] = useState<FormSubmissionState>(defaultFormState);
    const [formReadinessState, setFormReadinessState] = React.useState<NewDemoFormState>(defaultFormReadinessState);
    const [formValidationState, setFormValidationState] = React.useState<FormValidationState>(defaultFormValidationState);
    const [isAddingSharedDemoRequest, setIsAddingSharedDemoRequest] = React.useState<boolean>(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamTemplateName = queryParams.get('templateName');
    const [isTemplateActive, setIsTemplateActive] = useState<boolean | undefined>(undefined);

    const [pinClickthrough, setPinClickthrough] = React.useState<boolean>(false);

    const setSelectedTemplate = (selectedTemplate: Template, tenantsList: string[]) => {
        setIsTemplateActive(selectedTemplate.isActive);
        if (selectedTemplate.category === SharedDemosCategory) {
            onSharedDemoTemplateSelection(selectedTemplate);
        }
        else if (CommonUtils.compareStringIgnoreCase(selectedTemplate.demoType?.toString(), DemoType.clickthroughs.toString())) {
            pinUserClickthrough(selectedTemplate);
        }
        else {
            setFormReadinessState({
                ...formReadinessState,
                selectedTemplate: selectedTemplate,
            });

            setFormValidationState({
                ...formValidationState,
                allowedTenantsForTemplate: selectedTemplate.allowedDemoTenants,
                tenantsList: tenantsList,
                environmentNameRegularExpress: selectedTemplate.environmentNameRegex,
                environmentNameErrorMessage: selectedTemplate.environmentNameErrorMessage
            });
        }
    }

    const handleCancelClick = () => {
        setFormReadinessState(defaultFormReadinessState);
        setFormValidationState(defaultFormValidationState);
        setFormState(defaultFormState);
        setValues(formModel.props());
        setErrors('');
        const category = queryParams.get(UrlQueryParams.templateCategory)?.toString() || '';
        navigate({
            pathname: pageRoutes.newDemo,
            search: category && createSearchParams({
                category: category
            }).toString()
        });
    };

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        setValues
    } = useForm(formModel);

    const setFormError = (errorMessage: string, errorOptions?: any) => {
        setFormState({
            ...formState,
            serverError: errorOptions
        });
        telemetryLogger.trackException(errorMessage, errorOptions);
    }

    const onSharedDemoTemplateSelection = async (selectedTemplate: Template) => {
        try {
            setIsAddingSharedDemoRequest(true);
            let newDemoRequest: NewDemo = {
                DemoUserAccount: AuthProvider.getActiveAccount()?.username,
                EnvironmentName: sharedDemosEnvironmentNameMapping[selectedTemplate.name],
                TemplateId: selectedTemplate?.id,
                demoType: DemoType.shareddemos
            }

            let newDemoReqResponse = await DemoRequestSvc.newDemo(newDemoRequest);
            if (newDemoReqResponse.status === successCodes.accepted) {
                setIsAddingSharedDemoRequest(false);
                navigate(`${pageRoutes.demos.root}/${DemoType[DemoType.shareddemos]}`);
            }
            else {
                setFormError(messages.accessRequestSubmissionFailed, newDemoReqResponse.status);
                return;
            }
        }
        catch (e) {
            setFormError(messages.accessRequestSubmissionFailed, e);
        }
    }

    const pinUserClickthrough = async (selectedTemplate: Template) => {
        setPinClickthrough(true);

        let pinClickthroughResponse = await UserTemplateMgr.addUserTemplateMappingAsync(selectedTemplate);

        setPinClickthrough(false);

        if (pinClickthroughResponse) {
            navigate(`${pageRoutes.demos.root}/${DemoType[DemoType.clickthroughs]}`);
        }
    }

    const initNewDemoForm = async () => {
        try {

            var demoRequestId = getDemoRequestId();
            let demoRequest = await getDemoRequest();

            // get demo request details if id found in the url
            if (demoRequestId) {

                // TODO: add check if request status is not completed
                // TODO: optimize set form state
                if (!demoRequest || !demoRequest?.DemoRequestId) {
                    setFormState({
                        ...formState,
                        validatingDemoRequest: false,
                        demoProvisioningError: getDemoProvisioningError(
                            errorTypes.notFound,
                            messages.invalidDemoRequest
                        )
                    });

                    setFormReadinessState({
                        ...formReadinessState,
                        formReady: true,
                        selectedDemoRequest: demoRequest
                    });
                    return;
                }
            }

            // set defualt form values
            setValues({
                tenantUserName: demoRequest?.UserName,
                demoEnvironmentName: demoRequest?.EnvironmentName,
                userConsent: true
            })

        } catch (e) {
            setFormError(messages.demoFormFailingtoLoad, e);
        }
    }

    const validateNewDemoRequestForm = (e?: Event) => {
        var t = e?.target as HTMLInputElement;
        let validationChecks = validateNewRequestForm(t?.name, values, formValidationState, errors);
        setErrors(validationChecks);
        return Object.values(validationChecks).every(x => x === '');
    }

    const switchDemoAdminContext = async () => {
        const switchToArgs = {
            nextAccount: {
                memberName: values.tenantUserName
            }
        }
        await AuthProvider.switchTo(switchToArgs,
            onSwitchedUserContextSuccess,
            onSwitchedUserContextFailure);
    }

    const setRequestId = (requestId: string) => {
        navigate({
            pathname: pageRoutes.newDemo,
            hash: `#${requestId}`
        });
    }

    const onSubmitClick = async () => {
        try {
            setFormState({
                ...formState,
                demoProvisioningError: undefined
            });

            if (validateNewDemoRequestForm()) {

                let telemetryProps: TelemetryProperties = {
                    operationName: operationName.DemoRequestSvcNewDemoRequest,
                    exceptionType: ExceptionType.DemoServiceException,
                    defaultErrorCode: ErrorCode.FailedToCreateNewDemoRequest
                }

                let dimensions = new ExtendedMap<IDimension, string>();

                setFormState({
                    ...formState,
                    validatingDemoRequest: true
                });

                // TODO: Add Guid check for demo request id here
                const demoRequestId = getDemoRequestId();

                // save demo request if its a new demo request
                if (!demoRequestId) {

                    let newDemoRequest: NewDemo = {
                        DemoUserAccount: values.tenantUserName,
                        EnvironmentName: values.demoEnvironmentName,
                        TemplateId: formReadinessState?.selectedTemplate?.id,
                    }

                    let newDemoReqResponse = await DemoRequestSvc.newDemo(newDemoRequest);

                    dimensions.addDimension(Dimensions.DemoType, DemoType[newDemoRequest?.demoType || DemoType.mydemos]);

                    if (newDemoReqResponse.status === successCodes.accepted) {
                        setRequestId(newDemoReqResponse.data);

                        // Log trace and Set metrics
                        logAndSetApiSuccessMetrics(newDemoReqResponse, { ...telemetryProps, message: telemetryMessages.createNewDemoSuccess }, Metrics.NewDemoRequestAPICounter);
                    }
                    else if (newDemoReqResponse.status === errorCodes.forbidden) {
                        setFormState({
                            ...formState,
                            validatingDemoRequest: false,
                            demoProvisioningError: getDemoProvisioningError(
                                errorTypes.unauthorized,
                                messages.demoProvisioningUnauthorizedErrormessage
                            )
                        });

                        // Log exception and metrics
                        logErrorAndSetApiFailureMetrics(newDemoReqResponse, { ...telemetryProps, message: telemetryMessages.createNewDemoUnauthorized, defaultErrorCode: ErrorCode.UnauthorizedToCreateNewDemoRequest }, Metrics.NewDemoRequestAPIFailure);

                        return;
                    }
                    else {

                        setFormState({
                            ...formState,
                            validatingDemoRequest: false,
                            demoProvisioningError: getDemoProvisioningError(
                                errorTypes.badRequest,
                                messages.badRequestErrorMessage
                            )
                        });

                        // Log exception and metrics
                        logErrorAndSetApiFailureMetrics(newDemoReqResponse, { ...telemetryProps, message: telemetryMessages.createNewDemoError }, Metrics.NewDemoRequestAPIFailure);

                        return;
                    }
                }

                await switchDemoAdminContext();
            }

        } catch (e) {
            setFormError(messages.demoFromSubmissionFailed, e);
        }
    }

    const onSwitchedUserContextFailure = async (msalResponse: any) => {
        telemetryLogger.trackException(messages.switchingUserContextErrorForLogging);

        setFormState({
            ...formState,
            validatingDemoRequest: false,
            demoProvisioningError: getDemoProvisioningError(
                errorTypes.contextSwitchFailure,
                messages.switchingUserContextError
            )
        });
    }

    const onSwitchedUserContextSuccess = async (msalResponse: any) => {

        try {

            var demoAdminAccount = msalResponse.account;
            telemetryLogger.trackTrace(messages.switchingUserContextSuccess, demoAdminAccount?.username);

            // match the demo admin account with the demo request admin
            let demoRequest = await getDemoRequest();

            // TODO: add check if request status is not completed
            if (!demoRequest || !demoRequest?.DemoRequestId) {
                setFormState({
                    ...formState,
                    validatingDemoRequest: false,
                    demoProvisioningError: getDemoProvisioningError(errorTypes.notFound,
                        messages.invalidDemoRequest)
                });
                return;
            }

            let accountMismatchError = getDemoProvisioningError(errorTypes.adminAccountValidation,
                messages.adminAccountMismatch.replace('{signedin-account}', demoAdminAccount?.username).replace('{demo-request-admin}', demoRequest?.UserName)
            );

            if (demoRequest?.UserName?.toLowerCase() !== demoAdminAccount?.username?.toLowerCase()) {
                setFormState({
                    ...formState,
                    validatingDemoRequest: false,
                    demoProvisioningError: accountMismatchError
                });
                return;
            };

            let telemetryProps: TelemetryProperties = {
                operationName: operationName.DemoRequestSvcDemoProvisioning,
                exceptionType: ExceptionType.DemoServiceException,
                defaultErrorCode: ErrorCode.FailedToProvisionDemoEnvironment
            }

            // call start demo provisioning API
            let demoProvisiningRequest: DemoProvisioning = {
                DemoAdminAccount: demoAdminAccount,
                DemoRequestId: demoRequest?.DemoRequestId
            };

            DemoRequestSvc.startDemoProvisioning(demoProvisiningRequest).then((provisioningResponse) => {

                let dimensions = new ExtendedMap<IDimension, string>();
                let responseData: BaseApiResponse = provisioningResponse.data;
                if (!provisioningResponse?.hasError) {
                    if (responseData?.dependencyError) {
                        // Add dependency error from API response
                        let dependencyError: DependencyError = responseData.dependencyError;
                        let errorMessage: string = dependencyError.message;
                        let errorType: string = errorTypes.bapApiValidation;
                        let errorCode: string = dependencyErrCodeMapping[dependencyError.code];

                        let provisioningError = getDemoProvisioningError(errorType, errorMessage);

                        setFormState({
                            ...formState,
                            validatingDemoRequest: false,
                            demoProvisioningError: provisioningError
                        });

                        dimensions.addDimension(Dimensions.ErrorCode, getErrorCode(dependencyError, errorCode));

                        telemetryProps = {
                            ...telemetryProps,
                            message: errorMessage
                        };

                        // Log exception and metrics
                        logErrorAndSetApiFailureMetrics(
                            provisioningResponse,
                            telemetryProps,
                            Metrics.ProvisionDemoAPIFailure,
                            dimensions);
                    }
                    else {
                        // Log trace and Set metrics
                        logAndSetApiSuccessMetrics(
                            provisioningResponse,
                            {
                                ...telemetryProps,
                                message: telemetryMessages.demoProvisioningSuccess
                            },
                            Metrics.ProvisionDemoAPICounter,
                            dimensions);

                        navigate(pageRoutes.demos.root);
                        return;
                    }
                }
                else {
                    if (provisioningResponse.status == errorCodes.badRequest) {
                        setFormState({
                            ...formState,
                            validatingDemoRequest: false,
                            demoProvisioningError: getDemoProvisioningError(
                                errorTypes.badRequest,
                                messages.badRequestErrorMessage
                            )
                        });

                        dimensions.addDimension(Dimensions.ErrorCode, getErrorCode(provisioningResponse, ErrorCode.FailedToProvisionDemoEnvironment));

                        telemetryProps = {
                            ...telemetryProps,
                            message: responseData?.message || messages?.badRequestErrorMessage
                        };
                    }
                    else {
                        throw provisioningResponse;
                    }
                }
            }).catch((error) => {
                setFormState({
                    ...formState,
                    serverError: error
                });

                logUnhandledExceptionAndSetMetrics(
                    error,
                    {
                        ...telemetryProps,
                        message: messages.demoFromSubmissionFailed,
                        defaultErrorCode: ErrorCode.ProvisionDemoUnhandledError
                    },
                    Metrics.ProvisionDemoAPIFailure);
            });

        } catch (error) {
            setFormError(messages.demoFromSubmissionFailed, error);
        }
    }

    useEffect(() => {
        initNewDemoForm();
    }, [templateId]);

    const { serverError, isValidFormInput, validatingDemoRequest, demoProvisioningError } = formState

    return {
        formReadinessState,
        formErrors: errors,
        formValues: values,
        onInputChange: handleInputChange,
        onSubmitDemo: onSubmitClick,
        onCancelClick: handleCancelClick,
        onTemplateSelection: setSelectedTemplate,
        onFormValidation: validateNewDemoRequestForm,
        validatingDemoRequest: validatingDemoRequest,
        formSubmissionNotAllowed: demoProvisioningError?.errorType === errorTypes.notFound || validatingDemoRequest || Object.values(values).some(x => !x) || Object.values(errors).some(x => x !== ''),
        demoProvisioningError,
        isValidFormInput,
        error: serverError,
        isAddingSharedDemoRequest,
        addingClickthrough: pinClickthrough,
        templateName: queryParamTemplateName,
        isTemplateActive, 
    };
};