import { Template, TemplateResponse } from 'Models';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { ApiResult } from '../base/models';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { TemplatesContract } from './templatesSvcContract';
import { mapApiResponse } from './templatesSvc.transform';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';

const client = create(
    ApiName.Templates,
    createEnableMocksFn(async () => import('./templatesSvc.mock'))
);

export class TemplatesService implements TemplatesContract {

    public getAllTemplates = async (): Promise<ApiResult<TemplateResponse>> => {
        try {
            const response = await client.get('demos/templates');
            return apiSuccessResponse(response, mapApiResponse);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
    public updateTemplateStatus = async (demoTemplateId?: string, isActive?: boolean ): Promise<ApiResult<Template>> => {
        try {
            const response = await client.patch(`/demos/templates/${demoTemplateId}`, {
                isActive,
            });
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
    public validateTemplateToggleAdmin = async (): Promise<boolean> => {
        try {
            const response = await client.get('roles/validateToggleSecurityGroup');
            return response.data;
        } catch (error: any) {
            return false;
        }
    }
}