import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { initializeLocalization } from 'Providers/Localization';
import {
    Role, RoleSvc,
    UserRequestSvc,
    FeatureFlagSvc,
    TemplatesSvc
} from 'Services';
import {
    logAndSetApiSuccessMetrics,
    logErrorAndSetApiFailureMetrics,
    logUnhandledExceptionAndSetMetrics
} from 'Shared/telemetry/telemetryHelper';
import { operationName } from 'Shared/constants';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import {
    ErrorCode,
    Metrics
} from './Telemetry';

export const useGlobalContextProvider = () => {
    const [roles, setRoles] = useState<string[]>([]);
    const [featureFlags, setFeatureFlags] = useState<string[]>([]);
    const [validAccess, setValidAccess] = useState<boolean>(false);
    const [isUserRolesLoading, setUserRolesLoading] = useState<boolean>(true);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);;
    const { inProgress } = useMsal();
    const [isToggleSecurityGroupMember, setIsToggleSecurityGroupMember] = useState<boolean>(false);

    // TODO: Move this in a separate file 
    const loadFeatureFlags = async () => {
        FeatureFlagSvc.flags().then((apiResponse) => {
            let featureFlagSvcTelemetryProps: TelemetryProperties = {
                operationName: operationName.FeatureFlagSvcGetFlags,
                exceptionType: ExceptionType.FeatureFlagServiceException,
                defaultErrorCode: ErrorCode.FailedToGetFeatureFlagsInformation
            }
            if (apiResponse.hasError) {
                // TODO: Revisit degraded experience during feature flag v2 implementation.
                // Logging and suppressing error intentionally, so that user will not be blocked if feature flag api doesn't work as expected.
                // Note: All the feature flags will be considered as disabled if API call fails.
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(apiResponse, { ...featureFlagSvcTelemetryProps, message: telemetryMessages.getFeatureFlagsError }, Metrics.FeatureFlagAPIFailure);
                return;
            }
            setFeatureFlags(apiResponse?.data);
        });
    }

    const registerSeller = async (roles: string[]) => {
        // Register user as Seller if user has Demo_Seller role
        if (roles?.includes(Role.DEMOHUB_SELLER)) {
            let userSvcTelemetryProps: TelemetryProperties = {
                operationName: operationName.UserSvcRegisterSeller,
                exceptionType: ExceptionType.UserServiceException,
                defaultErrorCode: ErrorCode.FailedToRegisterUserInformation
            }
            const registerSellerApiResponse = await UserRequestSvc.registerSeller();
            if (registerSellerApiResponse.hasError) {
                setError(registerSellerApiResponse);
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(registerSellerApiResponse, { ...userSvcTelemetryProps, message: telemetryMessages.registerSellerError }, Metrics.UserAPIFailure);
                return;
            }

            // Log trace and Set metrics
            logAndSetApiSuccessMetrics(registerSellerApiResponse, { ...userSvcTelemetryProps, message: telemetryMessages.registerSellerSuccess }, Metrics.UserAPICounter);
        }
    }

    // TODO: Move this in a separate file 
    const loadUserRoles = async (): Promise<string[]> => {
        const rolesApiResponse = await RoleSvc.roles();
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.RoleSvcGetRoles,
            exceptionType: ExceptionType.RoleServiceException,
            defaultErrorCode: ErrorCode.FailedToGetUserRoleInformation
        }

        if (rolesApiResponse.hasError) {
            setError(rolesApiResponse);

            // Log exception and metrics
            logErrorAndSetApiFailureMetrics(
                rolesApiResponse,
                {
                    ...telemetryProps,
                    message: telemetryMessages.getRolesError
                },
                Metrics.RoleAPIFailure);

            return [];
        }

        // Log trace and Set metrics
        logAndSetApiSuccessMetrics(rolesApiResponse, { ...telemetryProps, message: telemetryMessages.getRolesSuccess }, Metrics.RoleAPICounter);

        setUserRolesLoading(false);
        setValidAccess(true);
        const userRoles = rolesApiResponse.data;
        setRoles(userRoles);
        return userRoles;
    }

    const validateSecurityGroup = async () => {
        
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.TemplateSvcToggleAdminSecurityGroup,
            exceptionType: ExceptionType.ToggleAdminSecurityGroupException,
            defaultErrorCode: ErrorCode.FailedToLoadToggleAdminSecurityGroup
        }
        
        try {
            const apiResponse = await TemplatesSvc.validateTemplateToggleAdmin();
            setIsToggleSecurityGroupMember(apiResponse);
            return apiResponse;
        } catch (error) {
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.ToggleAdminSecurityGroupException,
                    defaultErrorCode: ErrorCode.FailedToLoadToggleAdminSecurityGroup
                },
                Metrics.TemplateToggleSecurityGroupFailure);
        }
    };

    // TODO: move this in a separate file
    const loadPortalContent = async () => {
        try {
            await initializeLocalization();
        } catch (error) {
            setIsContentLoading(false);
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    operationName: operationName.ContentLoader,
                    message: telemetryMessages.contentLoadingError,
                    defaultErrorCode: ErrorCode.GlobalContextLoadContentFailed
                },
                Metrics.ContentLoaderAPIFailure);
        }
    }

    /**
     * Since i18n doesn't have any inbuilt mechanism to track if the requested namespace is loaded
     * Adding a delay of 1 second before rendering view to avoid any flicker issues on the UI
     * Will change it if find a more optimized way of handling this
     */
    const setContentLoadingFalse = () => {
        setTimeout(() => {
            setIsContentLoading(false);
        }, 1000)
    }    

    /**
     * Initialize global context
     * - Global context holds the implementation of global state variables e.g.
     * Content loading state
     * User Roles loading state
     * Available Feature Flags 
     */
    const initGlobalContext = async () => {
        // Load current user roles first
        // once userRoles are fetched, check if current user has any roles
        // execute rest of API i.e. content fetcher and feature flags only if user has any roles
        const userRoles = await loadUserRoles();
        if (userRoles && userRoles.length > 0) {
            // load portal content and register current user as seller
            await loadPortalContent();
            await registerSeller(userRoles);
            loadFeatureFlags();
            await validateSecurityGroup();

            // set flags
            setContentLoadingFalse();
        }

        // if current user doesn't have any roles, just make content loading as false
        setContentLoadingFalse();
    }

    useEffect(() => {
        // TODO: Don't add additional API call here as it will delay the componenet rendering. Must revisit design before adding any new API call here.
        if (inProgress === InteractionStatus.None) {
            try {
                initGlobalContext();
            } catch (error) {
                setError(error);
                logUnhandledExceptionAndSetMetrics(
                    error,
                    {
                        operationName: operationName.GlobalContextProvider,
                        message: telemetryMessages.globalContextRegistrationUnhandledError,
                        defaultErrorCode: ErrorCode.GlobalContextRegistrationFailed
                    },
                    Metrics.GlobalContextRegistrationFailure);
            }
        }
    }, [inProgress]);

    return {
        roles,
        featureFlags,
        isUserRolesLoading,
        isContentLoading,
        validAccess,
        error,
        isToggleSecurityGroupMember
    }
}