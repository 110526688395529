// NOTE: Keep Exception Type in alphabetical order
export enum ExceptionType {
    AccessTokenException = 'AccessTokenException',
    CopilotAssetsContentLoadException = 'CopilotAssetsContentLoadException',
    AssetNotFoundException = 'AssetNotFoundException', 
    CopilotAssetsFetchUpvotesException = 'CopilotAssetsFetchUpvotesException',
    CopilotAssetsUpdateUpvotesException = 'CopilotAssetsUpdateUpvotesException',
    DemoServiceException = 'DemoServiceException',
    FeatureFlagServiceException = 'FeatureFlagServiceException',
    MsalException = 'MsalException',
    NsatServiceException = 'NsatServiceException',
    RoleServiceException = 'RoleServiceException',
    TemplateServiceException = 'TemplateServiceException',
    UserServiceException = 'UserServiceException',
    RefreshDemoOrgsException ='RefreshDemoOrgsException',
    ContentServiceException = 'ContentServiceException',
    UserTemplateServiceException = 'UserTemplateServiceException',
    UpdateTemplateStatusException = 'UpdateTemplateStatusException',
    ToggleAdminSecurityGroupException = 'ToggleAdminSecurityGroupException'
}